#footer {
	background: $background__bg;

	.footer-inner {
		padding: 116px 0 62px;
	}

	.widget.footer-widget {
		.widget-title {
			color: #2b2350;
			font-size: 20px;
			font-weight: 500;
			margin-bottom: 38px;
			border-bottom: 0;
			padding-bottom: 0;
		}

		p {
			margin-bottom: 40px;
		}
	}

	.footer-menu {
		margin-bottom: 40px;
		padding: 0;
		list-style: none;

		li {
			&:not(:last-child) {
				margin-bottom: 10px;
			}

			a {
				display: block;
				color: #797687;
				font-weight: 400;
				line-height: 30px;

				&:hover {
					color: $color__theme;
				}
			}
		}
	}

	.footer-logo {
		display: block;
		margin-bottom: 30px;
	}

	.email-info {
		display: block;
		color: $color__theme;
		margin-bottom: 20px;

		i {
			margin-right: 10px;
		}
	}

	.widget-contact {
		.widget-contact-info {
			margin: 0;
			padding: 0;
			list-style: none;
			max-width: 240px;

			li {
				color: #b5b3be;
				font-weight: 300;
				display: flex;
				align-items: baseline;
				margin-bottom: 10px;

				i {
					margin-right: 10px;
					color: $color__theme-6;
				}
			}
		}
	}

	.copyright-text {
		margin-bottom: 50px;

		a {
			color: $color__theme;
		}
	}

	.footer-social-link {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;

			&:not(:last-child) {
				margin-right: 10px;
			}

			a {
				height: 45px;
				width: 45px;
				line-height: 45px;
				border: 1px solid #e6dbdb;
				display: block;
				border-radius: 50%;
				color: #9694a1;
				text-align: center;
				font-size: 16px;

				&:hover {
					background: $color__theme;
					border-color: $color__theme;
					color: #fff;
					box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
				}
			}
		}
	}

	.site-info {
		padding: 36px 0;
		display: flex;
		justify-content: space-between;
		border-top: 1px solid #e4d9d9;
		position: relative;
		z-index: 3;

		p {
			margin: 0;

			a {
				color: $color__theme;
				font-weight: 500;

				&:hover {
					color: darken($color__theme, 10%);
				}
			}
		}

	}

	&.footer-two {
		background: $background__2;

		.footer-menu {
			li {
				a {
					&:hover {
						color: $color__theme-2;
					}
				}
			}
		}

		.footer-social-link {
			li {
				a {
					&:hover {
						background: $color__theme-2;
						border-color: $color__theme-2;
						box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
					}
				}
			}
		}

		.site-info {
			border-color: #dad8e3;

			p {
				a {
					color: $color__theme-2;

					&:hover {
						color: darken($color__theme-2, 10%);
					}
				}
			}

		}

		.footer-social-link {
			li {
				a {
					border-color: #dad8e3;
				}
			}
		}

		.site-info-menu li a:hover {
			color: $color__theme-2;
		}
	}

	&.footer-three {
		position: relative;

		.footer-inner {
			position: relative;
			z-index: 3;
		}

		.map-bg {
			text-align: center;
			position: absolute;
			top: 30px;
			left: 0;
			z-index: 0;
			width: 100%;
		}
	}

	&.footer-four {
		.site-info {
			background: #f8f2f2;
			border-top: 1px solid #e4d9d9;
			padding: 25px 0;

			.site-info-inner {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}

	&.footer-five {
		background: #2c234d;

		.widget {

			&.footer-widget {
				.widget-title {
					color: #fff
				}
			}

			p {
				color: #a39eb9;
			}
		}

		.footer-menu {
			li {
				a {
					color: #a39eb9;

					&:hover {
						color: #7052fb;
					}
				}
			}
		}

		.footer-social-link {
			li {
				a {
					border-color: #615a7e;
					color: #a39eb9;

					&:hover {
						background: $color__theme-2;
						box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
						border-color: $color__theme-2;
						color: #fff;
					}
				}
			}
		}

		.site-info-menu li a {
			color: #9c99a7;

			&:hover {
				color: $color__theme-2
			}
		}

		.site-info {
			border-color: rgb(61, 53, 91);

			p {
				color: #9c99a7;

				a {
					color: $color__theme-2;
				}
			}
		}
	}

	&.footer-tax-service {
		background: #f2f7fe;
		position: relative;

		.footer-inner {
			padding: 116px 0 175px;
		}

		.email-info {
			color: $color__theme-4;
			margin-bottom: 20px;
		}

		.copyright-text {

			a {
				color: $color__theme-4;
			}
		}

		.footer-social-link {
			li {
				a {
					border-color: #d6dee9;
					color: #9198a4;

					&:hover {
						background: $color__theme-4;
						box-shadow: 0px 20px 30px 0px rgba(235, 56, 160, 0.3);
						color: #fff;
					}
				}
			}
		}

		.footer-menu {
			li {
				a {
					color: #505064;

					&:hover {
						color: $color__theme-4;
					}
				}
			}
		}

		.footer-bottom-element {
			.shape-element {
				margin: 0;
				padding: 0;
				list-style: none;
				width: 100%;

				li {
					position: absolute;
					bottom: 0;

					&:nth-child(2) {
						bottom: 0;
						left: 70px;

					}

					&:nth-child(3) {
						bottom: 0;
						left: 20px;
					}

					&:nth-child(4) {
						bottom: 20px;
						left: 40%;
					}

					&:nth-child(5) {
						bottom: 0;
						right: 120px;
					}

					&:nth-child(6) {
						bottom: 0;
						left: 200px;
					}
				}
			}
		}
	}

	&.footer-job-board {
		background-size: cover;
		background-position: center center;

		.footer-inner {
			padding: 115px 0 85px;

			@media(max-width: 991px) {
				padding: 75px 0 45px;
			}
		}

		.widget {
			&.footer-widget {
				.widget-title {
					color: #fff;
				}
			}
		}

		.footer-social-link {
			margin-bottom: 22px;

			li {
				a {
					background: rgba(255, 255, 255, 0.078);
					border: 0;
					color: #fff;

					&:hover {
						background: #fff;
						box-shadow: 0px 10px 20px 0px rgba(12, 118, 142, 0.2);
						color: $color__theme-5;
					}
				}
			}
		}


		.footer-menu {
			li {
				&:not(:last-child) {
					margin-bottom: 5px;
				}

				a {
					color: #fff;
					font-size: 14px;
					font-weight: 300;

					&:hover {
						color: $color__theme-5;
					}
				}
			}
		}

		.copyright-text {
			color: rgba(255, 255, 255, 0.502);

			a {
				color: $color__theme-5;
			}
		}
	}

	&.footer-app {
		padding-bottom: 50px;

		.widget {
			&.footer-widget {

				.widget-title {
					color: #fff;
				}
			}

			&.widget-about {
				max-width: 240px;

				p {
					color: #b5b3be;
					margin-bottom: 26px;
				}

				.footer-title {
					color: #fff;
					font-size: 20px;
					font-weight: 600;
					margin-bottom: 15px;
				}
			}
		}

		.footer-menu {
			li {
				&:not(:last-child) {
					margin-bottom: 5px;
				}

				a {
					color: #b5b3be;

					&:hover {
						color: $color__theme-6;
					}
				}
			}
		}

		.site-info {
			display: block;
			border-top-color: #2f3255;
			padding: 25px 0;

			p {
				color: #9997a2;

				a {
					color: $color__theme-6
				}
			}
		}
	}

	&.footer-marketing {
		background: #f8f9fd;
		position: relative;

		.footer-inner {
			padding: 116px 0 112px;
			position: relative;
			z-index: 222;
		}

		.widget.footer-widget {
			.widget-title {
				color: #1a133b;
			}
		}

		.footer-menu {
			li {

				&:not(:last-child) {
					margin-bottom: 5px;
				}

				a {
					color: #615f6b;
					font-size: 14px;

					&:hover {
						color: $color__theme-7;
					}
				}

			}
		}

		.footer-bottom-shape {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 0;
			z-index: 1;
		}
	}

	&.footer-hosting {
		background: #f2f7fe;
		position: relative;

		.footer-inner {
			padding: 116px 0 112px;
			position: relative;
			z-index: 222;
		}

		.widget.footer-widget {
			.widget-title {
				color: #1a133b;
			}
		}

		.footer-menu {
			li {

				&:not(:last-child) {
					margin-bottom: 5px;
				}

				a {
					color: #615f6b;
					font-size: 14px;

					&:hover {
						color: $color__theme-8;
					}
				}

			}
		}

		.footer-bottom-shape {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 0;
			z-index: 1;
		}
	}

	&.footer-travel {
		background: #f9f7fc;
		padding: 135px 0 100px;

		@media(max-width: 991px) {
			padding: 95px 0 60px;
		}

		.footer-widget {
			margin-bottom: 30px;
		}

		.widget-title {
			font-size: 18px;
			font-weight: 600;
			color: #0c082c;
			margin-bottom: 30px;
		}

		.travel-footer-about-widget {
			margin-bottom: 30px;

			.footer-subtitle {
				font-family: 'Satisfy', cursive;
				font-size: 20px;
				font-weight: 400;
				color: $color__theme-2;
				margin: 0;
			}

			.footer-title {
				font-size: 40px;
				font-weight: 700;
				color: #0c082c;
				margin: 0;

				@media(max-width: 1200px) {
					font-size: 30px;
				}
			}
		}

		.travel-contact-info {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: flex;
				font-size: 15px;
				margin-bottom: 10px;

				i {
					color: $color__theme-2;
					margin-right: 10px;
					line-height: 1;
					font-size: 18px;
				}

				p {
					margin-top: -5px;
					margin-bottom: 0;
				}
			}
		}

		.travel-footer-menu {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: block;
				margin-bottom: 3px;

				a {
					color: #68666c;
					font-size: 16px;

					&:hover {
						color: $color__theme-2;
					}
				}
			}
		}
	}
}


.site-info-menu {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;

		a {
			display: inline-block;
			color: #797687;

			&:hover {
				color: $color__theme;
			}
		}
	}
}


@media (max-width: 991px) {
	#footer {
		.footer-inner {
			padding: 76px 0 22px;
		}

		.site-info {
			padding: 21px 0;
		}
	}

	#footer {
		.widget {

			&.footer-widget {
				margin-bottom: 30px;

				.widget-title {
					margin-bottom: 25px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	#footer {
		.footer-menu {
			margin-bottom: 40px;
		}


		.site-info {
			padding: 21px 0;
			display: block;
			text-align: center;
		}

		&.footer-four {

			.copyright {
				margin-bottom: 20px;
			}

			.site-info {

				.site-info-inner {
					display: block;
				}
			}
		}
	}

	
}

/* Footer App Landing Two */
#footer-app-two {
	position: relative;
	background-color: #211341;
	padding-top: 140px;
	overflow: hidden;

	.footer-logo {
		margin-bottom: 42px;
	}

	.footer-widgets {
		position: relative;
		z-index: 1;
		padding-bottom: 106px;
	}

	.copyright-text {
		color: #c0bacf;
		font-size: 18px;
		font-weight: 300;
		line-height: 30px;
		margin-bottom: 52px;

		a {
			color: #c0bacf;

			&:hover {
				color: $color__theme-9;
			}
		}
	}

	.footer-social-link {
		li {
			margin-right: 8px;
			display: inline-block;

			a {
				background-color: rgba(161, 153, 153, 0.059);
				color: #c0bacf;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				border: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				box-shadow: none;

				&:hover {
					background-color: #fff;
					color: $color__theme-9;
				}
			}
		}
	}

	.widget-title {
		color: #fff;
		font-size: 20px;
		margin-bottom: 35px;
		border-bottom: 0;
	}

	.footer-menu {
		li {
			line-height: 40px;

			a {
				color: #c0bacf;

				&:hover {
					color: $color__theme-9;
				}

				&:after {
					background: $color__theme-9;
				}
			}
		}
	}

	.footer-app-shape-one {
		position: absolute;
		left: 9%;
		top: -55%;
		border-radius: 50%;
		width: 600px;
		height: 600px;
		background-color: rgba(255, 255, 255, 0.02);
	}

	.footer-app-shape-two {
		position: absolute;
		left: -3%;
		top: -66%;
		border-radius: 50%;
		width: 500px;
		height: 500px;
		background-color: rgba(255, 255, 255, 0.02);
	}
}

/* Footer App Three */
#footer-app-three {
	background-color: rgb(8, 11, 50);
	padding: 100px 0;



	@media(max-width: 991px) {
		padding: 70px 0 30px;

		.widget {
			margin-bottom: 40px;
		}
	}

	.widget-title {
		color: #fff;
		border-bottom: 0;
		padding-bottom: 0;
	}

	.footer-logo {
		margin-bottom: 25px;
	}

	.footer-menu {
		li {
			i {
				font-size: 13px;
				color: #fff;
				display: inline-block;
				margin-right: 5px;
			}
			a {
				color: rgba(255, 255, 255, 0.7);
				font-size: 16px;

				&:hover {
					color: #fff;
				}
			}
		}
	}

	.footer-social-link {
		li {
			display: inline-block;
			margin-right: 9px;

			a {
				display: block;
				height: 36px;
				width: 36px;
				background-color: $color__theme-10;
				border-radius: 10px;
				text-align: center;
				line-height: 36px;
				color: #fff;

				&.facebook {
					background-color: #326df4;
				}

				&.twitter {
					background-color: #1da1f2;
				}

				&.linkedin {
					background-color: #0077B5;
				}

				&.pinterest {
					background-color: #E60023;
				}
			}
		}
	}
}
