.swiper-button-prev,
.swiper-container-rtl .swiper-button-next,
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	background-image: none;
}

.testimonials {
	background: $background__bg;
	padding: 116px 0 0;
	overflow: hidden;

	.scroll-circle {
		bottom: 35%;
		right: -133px;
	}
}

.testimonial-wrapper {
	padding: 0 100px 160px;
	position: relative;
	z-index: 22;

	.shape-shadow {
		border-radius: 10px;
		background-color: rgb(255, 255, 255);
		opacity: 0.4;
		box-shadow: 0px 60px 100px 0px rgba(102, 74, 74, 0.2);
		height: 100px;
		max-width: 54%;
		position: absolute;
		bottom: 120px;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
	}

	.slider-nav {

		.swiper-button-next,
		.swiper-button-prev {
			color: #bdb4b4;
			font-size: 36px;
			transition: all 0.3s ease-in-out;
			outline: 0;


			&:hover {
				color: $color__theme;
			}
		}

		.swiper-button-next {
			right: 20px;
		}

		.swiper-button-prev {
			left: 20px;
		}
	}
}

#testimonial {
	border-radius: 10px;
	max-width: 770px;
	margin: 0 auto;
	position: relative;
	box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.1);
	background: #fff;
	width: 100%;

	.testimonial {
		text-align: center;
		margin: 0 auto;
		padding: 60px;
		position: relative;

		.testimonial-content {
			max-width: 630px;
			margin: 0 auto 30px;

			p {
				color: #656177;
				font-size: 20px;
				margin: 0;
				line-height: 38px;
			}
		}

		.single-bio-thumb {
			max-width: 80px;
			margin: 0 auto 41px;
			box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.2);
			border-radius: 50%;

			img {
				border-radius: 50%;
			}
		}

		.bio-info {
			.name {
				font-size: 20px;
				line-height: 32px;
				margin: 0;
			}

		}
	}

	.testimonial-travel {
		border-radius: 20px;
		box-shadow: 0px 50px 80px 0px rgba(13, 3, 31, 0.1);
	}
}


.testimonials-two {
	position: relative;
	overflow: hidden;

	.section-title {
		margin-bottom: 50px;
	}

	.animate-shape {
		position: absolute;
		right: -9%;
		top: -37%;
	}
}

#testimonial-wrapper {
	position: relative;
	z-index: 2;

	.slider-nav {

		.swiper-button-prev,
		.swiper-button-next {
			outline: 0;

			i {
				font-size: 32px;
			}

			&:hover {
				color: $color__theme-2
			}
		}

		.swiper-button-next {
			right: -100px;
		}

		.swiper-button-prev {
			left: -100px;
		}
	}
}

.testimonials-two,
.testimonials-two-about {

	padding: 116px 0 60px;

	.swiper-container {
		padding: 0 20px 100px;
	}
}

.testimonials-two {
	background: $background__2;
}

.testimonial-two {
	.testi-content-inner {
		overflow: hidden;
		position: relative;
		background: #fff;
		box-shadow: 0px 30px 20px 0px rgba(43, 35, 79, 0.05);
		border-radius: 10px;
		padding: 50px 60px;
	}

	.testimonial-bio {
		display: flex;
		align-items: center;
		margin-bottom: 43px;

		.avatar {
			height: 70px;
			width: 70px;
			border-radius: 50%;
			margin-right: 30px;

			img {
				border-radius: 50%;
			}
		}

		.bio-info {
			.name {
				font-size: 20px;
				font-weight: 600;
				margin-top: 0;
				margin-bottom: 5px;
			}
		}
	}

	.testimonial-content {
		margin-bottom: 35px;

		p {
			font-size: 18px;
			line-height: 30px;
			margin: 0;

		}
	}

	.shape-shadow {
		border-radius: 10px;
		background-color: rgb(255, 255, 255);
		opacity: 0.4;
		box-shadow: 0px 20px 50px 0px rgba(102, 74, 74, 0.2);
		height: 100px;
		max-width: 81%;
		position: absolute;
		bottom: -40px;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		z-index: -1;
	}

	.quote {
		position: absolute;
		right: 60px;
	}
}

.testimonial-travel-wrapper {
	position: relative;
	max-width: 770px;
	margin: 0 auto;

	.travel-testimonial {
		border-radius: 20px;
		background: #fff;
		box-shadow: 0px 50px 80px 0px rgba(13, 3, 31, 0.1);
		padding: 60px 50px 50px;
	}

	.testimonial-five {
		text-align: center;

		.single-bio-thumb {
			height: 80px;
			width: 80px;
			border-radius: 50%;
			margin: 0 auto 30px;
			overflow: hidden;
		}

		.rating {
			margin-bottom: 5px;
		}

		.testimonial-content {
			max-width: 550px;
			margin: 0 auto 25px;

			p {
				font-size: 20px;
				color: #0d031f;
				margin: 0;
				line-height: 32px;
			}
		}

		.bio-info {
			.name {
				font-size: 18px;
				font-weight: 700;
				color: #0d031f;
				margin: 0;
			}

			.job {
				font-size: 14px;
				color: #68666c;
			}
		}
	}

	.right-qoute {
		position: absolute;
		right: 33px;
		bottom: -14px;
	}

	.dot-shape {
		position: absolute;
		top: 60px;
		left: -40px;
	}
}



.rating {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		font-size: 14px;
		color: #fdae04;
	}
}

#testimonial-wrapper-three {
	max-width: 1060px;
	margin: 0 auto;
	position: relative;

	.quote {
		position: absolute;
		bottom: 80px;
		right: 80px;
		z-index: 22;
	}

	.swiper-container {
		padding: 0 45px 80px;
	}

	.slider-nav {
		width: 90px;
		top: auto;
		bottom: 100px;
		left: 50%;
		height: 40px;

		.swiper-button-prev {
			height: 25px;
			transition: all 0.3s ease-in-out;
			outline: 0;

			.arrow {
				margin-top: 12px;
				width: 25px;
				height: 2px;
				background: #bdb4b4;
				transition: all 0.3s ease-in-out;

				&:after {
					content: '';
					position: absolute;
					width: 15px;
					height: 2px;
					top: 7px;
					left: -2px;
					background-color: #bdb4b4;
					transform: rotate(-45deg);
					transition: all 0.3s ease-in-out;
				}

				&:before {
					content: '';
					position: absolute;
					width: 15px;
					height: 2px;
					top: 17px;
					left: -2px;
					background-color: #bdb4b4;
					transform: rotate(45deg);
					transition: all 0.3s ease-in-out;
				}


			}

			&:hover {
				width: 40px;
				left: -15px;

				.arrow {
					width: 40px;
					background: $color__theme;

					&:before,
					&:after {
						background: $color__theme;
					}
				}
			}


		}

		.swiper-button-next {
			height: 25px;
			transition: all 0.3s ease-in-out;

			.arrow {
				margin-top: 12px;
				width: 25px;
				height: 2px;
				background: #bdb4b4;
				transition: all 0.3s ease-in-out;

				&:after {
					content: '';
					position: absolute;
					width: 15px;
					height: 2px;
					top: 7px;
					right: -2px;
					background-color: #bdb4b4;
					transform: rotate(45deg);
					transition: all 0.3s ease-in-out;
				}

				&:before {
					content: '';
					position: absolute;
					width: 15px;
					height: 2px;
					top: 17px;
					right: -2px;
					background-color: #bdb4b4;
					transform: rotate(-45deg);
					transition: all 0.3s ease-in-out;
				}


			}

			&:hover {
				width: 40px;
				right: -15px;

				.arrow {
					width: 40px;
					background: $color__theme;

					&:before,
					&:after {
						background: $color__theme;
					}
				}
			}


		}
	}
}

.testimonials-two-about {
	background: $background__bg;
	padding: 116px 0 58px;
	overflow: hidden;

	.testimonial-two {

		.testi-content-inner {
			box-shadow: 0px 30px 20px 0px rgba(79, 35, 35, 0.1);
		}

		.shape-shadow {
			box-shadow: 0px 20px 50px 0px rgba(79, 35, 35, 0.1);
		}
	}

	.scroll-circle {
		bottom: 33%;
		z-index: 1;
		right: -7%;
	}

	#testimonial-wrapper .slider-nav .swiper-button-prev:hover,
	#testimonial-wrapper .slider-nav .swiper-button-next:hover {
		color: $color__theme;
	}
}

.testimonial-three {
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
	display: flex;
	flex-wrap: wrap;
	align-items: center;


	.avatar,
	.testimonial-content {
		flex: 1;
	}

	.testimonial-content {
		padding: 0 60px;
		margin-top: -47px;

		.bio-info {
			margin-bottom: 30px;

			.name {
				font-size: 20px;
				font-weight: 600;
			}
		}

		p {
			font-size: 18px;
			line-height: 32px;
			color: #656177;

		}
	}
}

.testimonials-three {
	padding: 116px 0 40px;
}

.testimonial-wrapper-two {
	.slider-nav {
		top: 50%;
	}
}

// Testomonial Five

.testimonials-four {
	background: #fbfafd;
	padding: 115px 0;
}

.testimonial-wrapper-two {
	position: relative;

	.slider-nav {

		#slide-prev,
		#slide-next {
			outline: 0;
			height: 50px;
			width: 50px;
			line-height: 54px;
			border: 1px solid #e0deed;
			text-align: center;
			font-size: 20px;
			border-radius: 50%;
			transition: all 0.3s ease-in-out;

			&:hover {
				background: $color__theme-2;
				box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
				color: #fff;
			}
		}
	}
}

.testimonials-tax {
	padding: 112px 0 120px;
	position: relative;

	.testi-top-shape {
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
	}
}

.testimonial-six-wrapper {
	margin: 0 auto;
	max-width: 770px;
	position: relative;

	.quote {
		position: absolute;
		top: 40px;
		left: 30px;
	}

	.testimonial-six {
		border-radius: 10px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 50px 80px 0px rgba(48, 11, 33, 0.1);
		padding: 50px 100px 100px;


		.testimonial {

			.single-bio-thumb {
				border-radius: 50%;
				background-color: rgb(223, 228, 236);
				box-shadow: 0px 30px 40px 0px rgba(48, 11, 33, 0.12);
				width: 80px;
				height: 80px;
				margin: 0 auto 30px;
				overflow: hidden;
			}

			.testimonial-content {
				p {
					font-size: 18px;
					line-height: 32px;
					margin-bottom: 35px;
				}
			}

			.bio-info {
				.name {
					font-size: 18px;
					line-height: 26px;
					margin: 0;
				}

				.job {
					font-size: 14px;
				}
			}
		}


	}

	.dot-shape {
		position: absolute;
		position: absolute;
		right: -60px;
		bottom: -35px;
		z-index: -1;
	}

}

.style-two {

	&.swiper-pagination {
		margin-top: 35px;

		.swiper-pagination-bullet {
			height: 12px;
			width: 12px;
			transition: all 0.3s ease-in-out;
			margin-right: 5px;
			position: relative;
			opacity: 1;
			background: transparent;
			outline: 0;


			&:before {
				content: '';
				position: absolute;
				height: 12px;
				width: 12px;
				background: rgb(229, 214, 214);
				z-index: 2;
				top: 0;
				left: 0;
				border-radius: 50%;
				left: -2px;
				top: -2px;
				transition: all 0.3s ease-in-out;
				transform: scale(0.7)
			}

			&.swiper-pagination-bullet-active {
				opacity: 1;

				&:before {
					opacity: 1;
					transform: scale(1);
					background: $color__theme-4;
					box-shadow: 0px 10px 14px 0px rgba(79, 35, 35, 0.3);
				}

			}
		}
	}
}

// Testimonial Six

.testimonials-six {
	padding: 120px 0 154px;
	background-size: cover;
	background-position: center center;
}

#testimonial-wrapper-six {
	margin: 0 -45px;

	.swiper-container {
		padding: 40px 45px 35px;
	}
}

.swiper-slide-visible {
	.testimonial-six {

		.testimonial-content {
			box-shadow: 0px 40px 50px 0px rgba(10, 1, 47, 0.12);
		}
	}

}

.testimonial-six {

	.testimonial-content {
		background-color: #fff;
		padding: 42px 40px;
		border-radius: 10px;
		transition: all 0.3s ease-in-out;
		position: relative;


		p {
			font-size: 16px;
			line-height: 30px;
			color: #322d49;
			margin-bottom: 13px;
		}

		&:after {
			content: '';
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 8px solid transparent;
			border-top: 15px solid #fff;
			bottom: -15px;
			position: absolute;
			left: 100px;
		}
	}

	.testimonial-bio {
		display: flex;
		align-items: center;
		margin-top: 35px;
		margin-left: 100px;

		.name {
			font-size: 18px;
			font-weight: 600;
			margin: 0;
		}

		.avatar {
			height: 50px;
			width: 50px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 20px;
		}
	}

	&.style-two {
		background-color: #fff;
		.testimonial-content {
			box-shadow: none;
			border-radius: 10px;
			background-color: rgb(255, 255, 255);
			box-shadow: 0px 2px 4px 0px rgba(0, 6, 83, 0.1);

			&:after {
				display: none;
			}

			p {
				color: #080b32;
			}
		}

		.testimonial-bio {
			margin: 0 0 25px 0;
		}
	}

}

// Testimonial App Three
.textimonial-app {
	.swiper-pagination.style-three .swiper-pagination-bullet.swiper-pagination-bullet-active {
		background: $color__theme-10;
	}
}

#testimonials-four {
	.swiper-slide {
		&.swiper-slide-active {
			.testimonial-four {
				>* {
					transform: translateY(0);
					opacity: 1;
				}

				.single-bio-thumb {
					transition-delay: 0.3s;
				}

				.testimonial-content {
					transition-delay: 0.5s;
				}

				.bio-info {
					transition-delay: 0.7s;
				}
			}
		}
	}
}

.testimonial-four {
	text-align: center;
	padding-top: 30px;

	>* {
		transform: translateY(-20px);
		opacity: 0;
		transition: all 0.5s;
	}

	.single-bio-thumb {
		overflow: hidden;
		height: 80px;
		width: 80px;
		margin: 0 auto 60px;
		border-radius: 50%;
		box-shadow: 0px 20px 30px 0px rgba(43, 35, 79, 0.1);
	}

	.testimonial-content {
		position: relative;
		max-width: 740px;
		margin: 0 auto 68px;

		.quote {
			position: absolute;
			top: -65px;
			left: 0;
			z-index: 1;
		}

		p {
			font-size: 20px;
			line-height: 36px;
			font-weight: 300;
			margin: 0;
			z-index: 22;
			position: relative;

		}

	}


	.bio-info {

		.name {
			font-size: 20px;
			font-weight: 500;
			color: #322d49;
			margin: 0;
		}

		.job {
			font-size: 14px;
			color: #7c7a8f;
		}
	}
}

.testimonials-marketing {
	padding: 100px 0;
	overflow: hidden;
}


#testimonial-marketing {
	.testimonial {
		max-width: 430px;

		.quote {
			margin-bottom: 32px;
		}

		.title {
			font-size: 30px;
			line-height: 42px;
			color: #1a133b;
			margin-bottom: 25px;
		}

		.testimonial-content {
			margin-bottom: 40px;
		}

		.author-wrapper {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.single-bio-thumb {
				width: 50px;
				margin-right: 20px;
			}

			.bio-info {
				flex: 2;

				.name {
					font-size: 14px;
					font-weight: 600;
					color: #1a133b;
					margin: 0;
				}

				.job {
					color: #7c7a86;
					font-size: 14px;
				}
			}
		}
	}
}

.devaider {
	position: absolute;
	top: 75px;
	left: -187px;

	@media(max-width: 768px) {
		display: none;
	}

}

/* Testimonial Hosting */
.testimonial-hostings {
	padding: 115px 0;
}

.testimonial-hosting-wrapper {
	position: relative;
	max-width: 1075px;
	margin: 0 auto;

	#testimonial-hosting {
		padding-bottom: 80px;
	}

	.swiper-pagination {
		position: absolute;
		left: auto !important;
		right: -40px;
		width: auto;
		margin: 0;
		bottom: 28px;

		.swiper-pagination-bullet {
			height: 10px;
			width: 10px;
			background: #cbd1d4;
			position: relative;
			margin-right: 10px;
			opacity: 1;

			&:before {
				position: absolute;
				content: '';
				height: 18px;
				width: 18px;
				top: -4px;
				left: -4px;
				border: 1px solid transparent;
				border-radius: 50%;
				transition: all 0.3s ease-in-out;
				transform: scale(0.5);
			}

			&.swiper-pagination-bullet-active {
				background: $color__theme-8;

				&:before {
					border-color: $color__theme-8;
					transform: scale(1);
				}
			}
		}
	}

}


.testimonial-hots {
	text-align: center;
	margin: 0 auto;
	padding: 60px 60px 0 60px;
	position: relative;
	border-top: 2px solid #f0f0f0;
	border-left: 2px solid #f0f0f0;
	border-right: 2px solid #f0f0f0;

	@media (max-width: 768px) {
		padding: 40px 30px 0 40px;
	}

	@media (max-width: 768px) {
		padding: 30px 30px 0 30px;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		height: 2px;
		width: 28%;
		background: #f0f0f0;

		@media(max-width: 991px) {
			width: 18%;
		}

		@media(max-width: 768px) {
			width: 7%;
		}

	}

	&:before {
		left: 0;
		bottom: 0;
	}

	&:after {
		right: 0;
		bottom: 0;
	}

	* {

		transform: translateY(-20px);
		opacity: 0;
		transition: all 0.5s;
	}

	.swiper-slide-active & {
		* {
			transform: translateY(0);
			opacity: 1;
		}

		.testimonial-content {
			transition-delay: 0.3s;
		}

		.bottom-info {
			transition-delay: 0.5s;
		}
	}

	.testimonial-content {
		max-width: 670px;
		margin: 0 auto;

		p {
			font-size: 18px;
			line-height: 36px;

			@media(max-width: 480px) {
				font-size: 16px;
				line-height: 30px;
			}
		}
	}

	.bottom-info {
		display: flex;
		justify-content: space-around;
		transform: translateY(50px);
		margin-left: 55px;

		@media (max-width: 480px) {
			margin-left: 0;
		}
	}

	.author-wrapper {
		display: flex;
		align-items: center;
		text-align: left;

		.single-bio-thumb {
			border-radius: 4px;
			margin-right: 35px;
			position: relative;

			@media (max-width: 480px) {
				margin-right: 20px;
				width: 80px;
				height: 100px;
			}

			img {
				border-radius: 4px;
				position: relative;
				z-index: 2;
			}

			&:before {
				content: '';
				position: absolute;
				border-radius: 4px;
				width: 130px;
				height: 170px;
				border: 2px solid #f0f0f0;
				top: -20px;
				left: -60px;

				@media(max-width: 480px) {
					width: 80px;
					height: 118px;
					border: 2px solid #f0f0f0;
					top: -15px;
					left: -17px;
				}
			}
		}

		.bio-info {
			.name {
				font-size: 20px;
				color: #2c234d;
				margin: 0;

				@media(max-width: 480px) {
					font-size: 16px;
				}
			}

			.job {
				font-size: 16px;
				color: #2c234d;

				@media(max-width: 480px) {
					font-size: 14px;
				}
			}
		}
	}
}


@media (max-width: 1440px) {

	.testimonials-two,
	.testimonials-two-about {

		.animate-shape,
		.scroll-circle {
			max-width: 600px;
		}
	}
}

@media (max-width: 1200px) {
	#testimonial-wrapper-three {
		.slider-nav {
			bottom: 70px;
		}

	}
}

@media (max-width: 1024px) {
	.testimonial-three {
		.testimonial-content {
			padding: 0 20px;
			margin-top: 0;

			p {
				font-size: 16px;
				line-height: 28px;
			}

			.bio-info {
				margin-bottom: 10px;
			}
		}
	}

	#testimonial-wrapper-three {
		.slider-nav {
			bottom: 80px;
		}

		.quote {
			width: 80px;
		}
	}

	.testimonials {
		.scroll-circle {
			max-width: 500px;
		}
	}

	.testimonials-two-about {
		.scroll-circle {
			max-width: 400px;
		}
	}
}

@media (max-width: 991px) {
	.testimonials {
		padding: 76px 0 10px;
	}

	.testimonials-two .swiper-container,
	.testimonials-two-about .swiper-container {
		padding: 0 20px 80px;
	}

	.testimonials-two,
	.testimonials-two-about {
		padding: 76px 0 30px;
	}

	#testimonial-wrapper {
		.slider-nav {
			.swiper-button-prev {
				left: -30px;
			}

			.swiper-button-next {
				right: -20px;
			}
		}
	}

	.testimonials-three {
		padding: 76px 0 0;
	}

	#testimonial-wrapper-three {
		.slider-nav {
			display: none;
		}
	}

	.testimonials-two {
		.animate-shape {
			right: -80%;
		}
	}

	.testimonial-wrapper {
		padding: 0 50px 110px;

		.slider-nav {
			.swiper-button-prev {
				left: 0;
			}

			.swiper-button-next {
				right: 10px;
			}
		}

		.shape-shadow {
			bottom: 80px;
		}
	}

	#testimonial {
		.testimonial {
			.testimonial-content {
				p {
					font-size: 18px;
					line-height: 34px;
				}
			}
		}

	}

	.testimonial-two {
		.testi-content-inner {
			padding: 40px 30px;
		}

		.shape-shadow {
			bottom: -30px;
		}
	}

	.testimonial-four {
		.single-bio-thumb {
			margin: 0 auto 30px;
		}

		.testimonial-content {
			max-width: 500px;
			margin: 0 auto 38px;

			p {
				font-size: 18px;

				br {
					display: none;
				}
			}
		}
	}

	.testimonials-four {
		padding: 50px 0 70px;
	}

	.testimonials-tax {
		padding: 72px 0 80px;
	}
}

@media (max-width: 768px) {

	.testimonials {
		padding: 76px 0 30px;
	}

	.testimonials-three {
		padding: 76px 0 30px;
	}

	#testimonial-wrapper-three .swiper-container {
		padding: 0 25px 50px;
	}

	#testimonial {
		.testimonial {
			padding: 40px;

			.testimonial-content {

				p {
					font-size: 16px;
					line-height: 30px;
				}
			}
		}
	}

	.testimonial-wrapper {
		padding: 0 50px 70px;

		.shape-shadow {
			bottom: 50px;
		}
	}

	#testimonial-wrapper {
		.slider-nav {
			.swiper-button-prev {
				left: -30px;
			}

			.swiper-button-next {
				right: -20px;
			}
		}
	}

	.testimonials-two .animate-shape {
		right: -100%;
	}

	.testimonial-three {
		display: block;
		padding: 30px;

		.avatar {
			width: 80px;
			height: 80px;
			border-radius: 50%;
			margin: auto 30px;
			overflow: hidden;

			img {
				border-radius: 50%;
			}
		}
	}

	#testimonial-wrapper-three {
		.slider-nav {
			bottom: 50px;
			transform: translateX(-50%);
			display: none;
		}

		.quote {
			bottom: 50px;
		}
	}

	.testimonial-wrapper-two .slider-nav #slide-prev {
		left: -50px;
	}

	.testimonial-wrapper-two .slider-nav #slide-next {
		right: -50px;
	}

	.testimonial-six-wrapper .testimonial-six {
		padding: 50px 50px 100px;
	}

}

@media (max-width: 620px) {
	.testimonial-four {
		.testimonial-content {
			p {
				font-size: 16px;
				line-height: 26px;
			}
		}
	}

	.testimonial-wrapper-two .slider-nav {
		display: none;
	}
}

@media (max-width: 576px) {

	#testimonial-wrapper,
	.testimonial-wrapper {
		.slider-nav {

			.swiper-button-prev,
			.swiper-button-next {
				display: none;
			}
		}
	}

	.testimonial-wrapper {
		padding: 0 0 70px;
	}

	#testimonial .testimonial {
		padding: 40px 25px;
	}

	#testimonial-wrapper-three {
		.slider-nav {
			display: none;

		}

		.swiper-container {
			padding: 0 20px 50px !important;
		}

		.quote {
			right: 30px;
		}
	}


	.testimonial-three {
		padding: 30px 10px;
	}
}

/* Testimonial Travel */
.testimonial-travel {
	background: #f4f2f7;
	padding: 120px 0;

	@media(max-width: 991px) {
		padding: 80px 0;
	}

}

.logo-carousel-travel {
	margin-bottom: 86px;
}




#testimonial-app-two {
	padding: 165px 0 120px;

	@media (max-width:991px) {
		padding: 80px 0;
	}

	.testimonial-app-wrapper {
		padding-top: 255px;

		@media (max-width:991px) {
			padding-top: 100px;
		}

		.app-section-heading {
			margin-bottom: 42px;
		}
	}

	.swiper-container {
		margin: 0 -15px;
		padding: 26px 40px;
	}

	.testimonia-single {
		.testimonial {
			&.style-two {
				border-radius: 0;
				padding: 38px 50px 31px 60px;
				text-align: left;
				overflow: initial;
				position: relative;
				box-shadow: 0px 30px 70px 0px rgba(75, 3, 47, 0.08);

				.info-content {
					.name {
						font-weight: 500;
					}

					.designation {
						font-size: 16px;
					}
				}

				p {
					font-size: 18px;
					font-weight: 300;
					line-height: 32px;

				}
			}
		}

		.user-details {
			margin-bottom: 25px;
		}

		.avatar {
			position: absolute;
			left: -90px;

			@media(max-width: 576px) {
				position: static;
				
			}
		}

		.content {
			p {
				margin-bottom: 9px;
			}
		}
	}

	.swiper-pagination {
		margin-top: 72px;
		position: relative;

		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			margin: 0 6px !important;
			background: #dce4f2;
			position: relative;
			opacity: 1;

			&::before {
				content: '';
				position: absolute;
				top: -2px;
				bottom: -2px;
				left: -2px;
				right: -2px;
				border-radius: 50%;
				transform: scale(0.5);
				opacity: 0;
				transition: all 0.3s ease-in-out;
			}

			&.swiper-pagination-bullet-active {


				&::before {
					background: $color__theme-9;
					transform: scale(1);
					opacity: 1;
				}
			}
		}
	}

	.testimonial-element-shape {
		position: absolute;
		top: 150px;
		left: -7.8%;
	}
}

.testimonials-app-three {
	padding: 110px 0 150px;

	@media(max-width: 991px) {
		padding: 80px 0 100px;
	}
}

.swiper-pagination {
	margin-top: 72px;

	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		margin: 0 6px !important;

		&::before {
			background-color: $color__theme;
		}
	}
}

.testimonial-element-shape {
	position: absolute;
	top: 150px;
	left: -7.8%;
}



.testimonial-app {
	border-radius: 0;
	padding: 38px 50px 31px 60px;
	text-align: left;
	overflow: initial;
	position: relative;
	box-shadow: 0px 30px 70px 0px rgba(75, 3, 47, 0.08);
	background: #fff;

	@media(max-width: 576px) {
		padding: 38px 35px 31px 35px;
	}

	.name {
		font-weight: 500;
		font-size: 18px;
		margin: 0;
	}

	p {
		@media (max-width: 1200px) {
			br {
				display: none;
			}
		}
	}

	.ratings {
		margin: 0;
		padding: 0;
		list-style: none;
		position: absolute;
		top: 0;
		right: 0;

		@media(max-width: 480px) {
			position: static;
		}

		li {
			display: inline-block;
			font-size: 12px;
			display: inline-block;
			color: #fbc509;
		}
	}

	.user-details {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;
		position: relative;
		align-items: center;
	}
}